import React from "react";
import { RangeFinder } from "src/types/clue";
import classes from "./RangeFinderInfo.module.scss";

interface Props {
  rangeItem: RangeFinder;
}

const CantPurchaseReason = ({ rangeItem }: Props): JSX.Element => {
  if (rangeItem.is_disabled) {
    return (
      <p className={classes.availableOn}>
        Available on day {rangeItem.available_on_day}
      </p>
    );
  } else if (rangeItem.is_purchased) {
    return (
      <p className={classes.alreadyPurchased}>Already purchased this cycle</p>
    );
  }
  return <></>;
};

export default CantPurchaseReason;
