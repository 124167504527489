import get from "lodash/get";
import { toast } from "react-toastify";
import { Dispatch } from "redux";
import {
  ClueStatusProps,
  FilterParams,
  PurchaseHintProps,
  PurchaseRangeFinderAdvantageClueProps,
  PurchaseTreasureViewsAdvantageClueProps,
  editClueStatus as editClueStatusApi,
  editCluesAnswer as editCluesAnswerApi,
  getAdvantageCluesPurchaseHistoryData as getAdvantageCluesPurchaseHistoryDataApi,
  getClueTypes as getClueTypesApi,
  getClues as getCluesApi,
  getRangeFinderBuyOptions as getRangeFinderBuyOptionsApi,
  getSeasons as getSeasonsApi,
  getTreasureViewsBuyOptions as getTreasureViewsBuyOptionsApi,
  postCluesAnswer as postCluesAnswerApi,
  purchaseHint as purchaseHintApi,
  purchaseHintList as purchaseHintListApi,
  purchaseRangeFinderAdvantageClue as purchaseRangeFinderAdvantageClueApi,
  purchaseTreasureViewsAdvantageClue as purchaseTreasureViewsAdvantageClueApi,
} from "src/api/clue";
import { AnswerProps, ClueProps } from "src/pages/Play/Clue/types";
import { RootState } from "..";
import Types from "../types/clue";

export const setClueModalState = (isOpen: boolean, data: ClueProps | null) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.CLUE_MODAL_TOGGLE,
      payload: {
        isOpen,
        data,
      },
    });
  };
};
export const setCluePurchaseModalState = (isOpen: boolean, index?: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.PURCHASE_MODAL_TOGGLE,
      payload: {
        isOpen,
        index,
      },
    });
  };
};

export const setSeasonInfo = (data: { id: number }) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.SET_SEASON_INFO,
      payload: {
        data,
      },
    });
  };
};

export const setClueData = (currentClue: ClueProps | null) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.SET_CLUE_DATA,
      payload: {
        currentClue,
      },
    });
  };
};

export const getClueTypes = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.FETCH_CLUE_TYPES_STARTED,
    });

    const resp = await getClueTypesApi();
    const types = resp?.data?.types || {};

    if (resp?.status) {
      dispatch({
        type: Types.FETCH_CLUE_TYPES_SUCCESS,
        payload: { data: types },
      });
    } else {
      dispatch({
        type: Types.FETCH_CLUE_TYPES_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const getClues = (region_id: string, params: FilterParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.FETCH_CLUES_STARTED,
    });
    const resp = await getCluesApi(region_id, params);
    const resultClues: ClueProps[] = resp?.data?.clues || [];
    // let clues: ClueProps[] = [];

    // if (tab === "clues") {
    //   clues = resultClues || [];
    // } else if (tab === "completed-clues") {
    //   clues =
    //     resultClues.filter((clue) => {
    //       return isClueCompleted(clue);
    //     }) || [];
    // } else if (tab === "not-completed-clues") {
    //   clues =
    //     resultClues.filter((clue) => {
    //       return !isClueCompleted(clue);
    //     }) || [];
    // } else {
    //   clues = resultClues || [];
    // }

    if (resp?.status) {
      dispatch({
        type: Types.FETCH_CLUES_SUCCESS,
        payload: { data: resultClues },
      });
    } else {
      dispatch({
        type: Types.FETCH_CLUES_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const purchaseHint = (clueId: number, data: PurchaseHintProps) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.PURCHASE_CLUE_HINT_STARTED,
    });
    const resp = await purchaseHintApi(clueId, data);
    const clues = resp?.data?.clues || [];

    if (resp?.status) {
      dispatch({
        type: Types.PURCHASE_CLUE_HINT_SUCCESS,
        payload: { data: clues },
      });
    } else {
      dispatch({
        type: Types.PURCHASE_CLUE_HINT_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const postCluesAnswer = (
  clue_id: number | undefined,
  answer: string,
  params: { answer_number: number },
  cb?: (clues: ClueProps[]) => void
) => {
  return async (dispatch: Dispatch, getState: RootState) => {
    dispatch({
      type: Types.POST_CLUE_ANSWER_STARTED,
    });

    const resp = await postCluesAnswerApi(clue_id, answer, params);

    const clues = getState().clue?.clues?.data || [];
    const updatedAnswers = (resp?.data?.answers || []) as {
      answer: AnswerProps;
    }[];

    const updatedClues = clues.map((c: ClueProps) => {
      const found = clue_id === c.id;
      if (found) {
        const isAllAnswersCorrect = updatedAnswers.every(
          (i) => !!i.answer.is_correct
        );
        return {
          ...c,
          answers: updatedAnswers,
          answer_status: isAllAnswersCorrect ? "complete" : "invalid",
          status: isAllAnswersCorrect ? "complete" : "invalid",
        };
      }
      return c;
    });

    if (cb) {
      cb(updatedClues);
    }
    if (resp?.status) {
      dispatch({
        type: Types.POST_CLUE_ANSWER_SUCCESS,
        payload: { data: clues },
      });
      dispatch({
        type: Types.FETCH_CLUES_SUCCESS,
        payload: { data: updatedClues },
      });
    } else {
      dispatch({
        type: Types.POST_CLUE_ANSWER_FAILED,
        payload: {
          message: resp?.message,
        },
      });
      toast.error("Something went wrong");
    }
    return resp;
  };
};

export const editCluesAnswer = (
  clue_id: number | undefined,
  answerId: number | undefined,
  answer: string,
  params: { answer_number: number },
  cb?: (clues: ClueProps[]) => void
) => {
  return async (dispatch: Dispatch, getState: RootState) => {
    dispatch({
      type: Types.EDIT_CLUE_ANSWER_STARTED,
    });

    const resp = await editCluesAnswerApi(answerId, answer, params);
    const updatedAnswers = (resp?.data?.answers || []) as {
      answer: AnswerProps;
    }[];

    const clues = getState().clue?.clues?.data || [];
    const updatedClues = clues.map((c: ClueProps) => {
      const found = clue_id === c.id;

      if (found) {
        const isAllAnswersCorrect = updatedAnswers.every(
          (i) => !!i.answer.is_correct
        );
        return {
          ...c,
          answers: updatedAnswers,
          answer_status: isAllAnswersCorrect ? "complete" : "invalid",
          status: isAllAnswersCorrect ? "complete" : "invalid",
        };
      }
      return c;
    });
    if (cb) {
      cb(updatedClues);
    }

    if (resp?.status) {
      dispatch({
        type: Types.FETCH_CLUES_SUCCESS,
        payload: { data: updatedClues },
      });
      dispatch({
        type: Types.EDIT_CLUE_ANSWER_SUCCESS,
        payload: { data: clues },
      });
    } else {
      dispatch({
        type: Types.EDIT_CLUE_ANSWER_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const editClueStatus = (clue_status: ClueStatusProps[]) => {
  return async (dispatch: Dispatch, getState: RootState) => {
    dispatch({
      type: Types.EDIT_CLUE_STATUS_STARTED,
    });

    const resp = await editClueStatusApi(clue_status);
    const currentClues = getState().clue?.clues?.data || [];
    const clues = resp?.data?.clues || [];

    const updatedClues = currentClues.map((c: ClueProps) => {
      const found = clue_status.find((clue) => clue.id === c.id);

      if (found) {
        return {
          ...c,
          ...found,
          answer_status:
            c.answer_status === "not-viewed" ? "viewed" : c.answer_status,
        };
      } else {
        return { ...c, is_current: false };
      }
    });

    if (resp?.status) {
      dispatch({
        type: Types.EDIT_CLUE_STATUS_SUCCESS,
        payload: { data: clues },
      });
      dispatch({
        type: Types.FETCH_CLUES_SUCCESS,
        payload: { data: updatedClues },
      });
    } else {
      dispatch({
        type: Types.EDIT_CLUE_STATUS_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const purchaseHintList = (clue_id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.FETCH_PURCHASE_HINT_LIST_STARTED,
    });

    const resp = await purchaseHintListApi(clue_id);
    const data = resp?.data || [];

    if (resp?.status) {
      dispatch({
        type: Types.FETCH_PURCHASE_HINT_LIST_SUCCESS,
        payload: { data: data },
      });
    } else {
      dispatch({
        type: Types.FETCH_PURCHASE_HINT_LIST_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const getSeasons = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.FETCH_SEASONS_STARTED,
    });

    const resp = await getSeasonsApi();
    const seasons = get(resp, "data.seasons", []);

    if (resp?.status) {
      dispatch({
        type: Types.FETCH_SEASONS_SUCCESS,
        payload: { data: seasons },
      });
    } else {
      dispatch({
        type: Types.FETCH_SEASONS_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const interactiveWordPuzzleUpdate = (answer: string, error = "") => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.INTERACTIVE_WORD_PUZZLE_STATE_UPDATE,
      payload: { answer: answer, error: error },
    });
  };
};

export const getAdvantageCluesPurchaseHistoryData =
  (lamp: number, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_ADVANTAGE_CLUES_PURCHASE_HISTORY,
      asyncCall: () => {
        return getAdvantageCluesPurchaseHistoryDataApi(lamp);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };

export const purchaseRangeFinderAdvantageClue =
  (data: PurchaseRangeFinderAdvantageClueProps, cb?: any) =>
  (dispatch: Dispatch) => {
    return dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.PURCHASE_RANGE_FINDER_ADVANTAGE_CLUE,
      asyncCall: () => {
        return purchaseRangeFinderAdvantageClueApi(data);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const purchaseTreasureViewsAdvantageClue =
  (data: PurchaseTreasureViewsAdvantageClueProps, cb?: any) =>
  (dispatch: Dispatch) => {
    return dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.PURCHASE_TREASURE_VIEWS_ADVANTAGE_CLUE,
      asyncCall: () => {
        return purchaseTreasureViewsAdvantageClueApi(data);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };

export const getRangeFinderBuyOptions =
  (lamp: number, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.RANGE_FINDER_BUY_OPTIONS,
      asyncCall: () => {
        return getRangeFinderBuyOptionsApi(lamp);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };

export const getTreasureViewsBuyOptions =
  (lamp: number, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.TREASURE_VIEWS_BUY_OPTIONS,
      asyncCall: () => {
        return getTreasureViewsBuyOptionsApi(lamp);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };

export const resetAdvantageCluesData = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.RESET_ADVANTAGE_CLUES_DATA,
      payload: [],
    });
  };
};
