import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getClueTypes, setClueData } from "src/store/actions/clue";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { useClueActions, useRegionActions } from "src/utils/useActions";
import { useCluesData } from "src/utils/useData";
import BreadCrumbs from "../BaseComponents/BreadCrumbs";
import ClueDashboard from "./Components/ClueDashboard";
import QuestionModal from "./Components/ClueDashboard/Clues/QuestionModal";
import Header from "./Components/Header";
import classes from "./styles.module.scss";
import { ClueProps } from "./types";
import { getCurrentClue } from "./utils";

const Clue = () => {
  const [searchParams] = useSearchParams();
  const regionId = searchParams.get("region") || "region-1";
  const season = searchParams.get("season") || "1";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [regionLoading, setRegionLoading] = useState(false);
  const { getClues } = useClueActions();
  const actions = useStoreActions({ setClueData, getClueTypes });
  const { getRegion } = useRegionActions();
  const { isOpen } = useReducerData("clue", "clueModal", {
    isOpen: false,
    data: {},
  });

  const {
    clues: { data: clues },
  } = useCluesData();

  const currentClue = getCurrentClue(clues);

  useEffect(() => {
    actions.getClueTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchClues = async () => {
      setLoading(true);
      await getClues(regionId, { season });
      setLoading(false);
    };
    fetchClues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [season]);

  useEffect(() => {
    const fetchRegion = async () => {
      setRegionLoading(true);
      await getRegion(regionId);
      setRegionLoading(false);
    };
    fetchRegion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionId]);

  return (
    <div className={classes.container}>
      {isOpen ? (
        <QuestionModal isOpen={isOpen} data={currentClue as ClueProps} />
      ) : null}
      <BreadCrumbs onBack={() => navigate("/play")} />
      <div className={classes.dashboardWrapper}>
        <Header />
        <ClueDashboard loading={loading || regionLoading} />
      </div>
    </div>
  );
};

export default Clue;
