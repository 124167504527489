import cn from "classnames";
import { useLocation } from "react-router-dom";
import AppDownload from "src/components/AppDownload";
import Button from "src/components/Button";
import Typography from "src/components/Typography";
import useUserProfile from "src/helpers/hooks/useUserProfile";
import CopyrightIcon from "src/pages/Home/assets/icons/copy-right-icon.svg";
import Tsg from "src/pages/Home/assets/images/tgs-img.svg";
import { useWindowSize } from "src/utils/useWindowSize";
import { currentYear } from "src/utils/utils";
import classes from "./styles.module.scss";
const TGS_BASE_URL = "https://www.treasuregames.fun";
const tawkWidgetId = process.env.REACT_APP_TAWK_WIDGET_ID || "";
const tawkPropertyId = process.env.REACT_APP_TAWK_PROPERTY_ID || "";

const Footer = () => {
  const userProfile = useUserProfile();
  const { pathname } = useLocation();
  const isDeviceBlockedPage = pathname === "/user/device-blocked";
  const { active_subscription = false } = userProfile;
  const { width = 0 } = useWindowSize();
  const isMobile = width <= 768;
  const copyRightAndLink = (
    <>
      <Typography
        className={classes.copyRight}
        variant="p12"
        fontFamily="inter"
      >
        <img src={CopyrightIcon} alt="copy-right" /> Copyright {currentYear}
      </Typography>
      <div className={classes.links}>
        <a href={`${TGS_BASE_URL}/a/privacy-policy-ip-policy`}>Privacy</a>{" "}
        <span></span>
        <a href={`${TGS_BASE_URL}/a/terms-and-conditions`}>
          Terms and Conditions
        </a>
        <span></span>
        <a href={`${TGS_BASE_URL}/a/tg-risk-waiver`}>Risk Waiver</a>
      </div>
    </>
  );

  return (
    <footer
      className={cn(classes.footerWrapper, {
        [classes.isDeviceBlockedPage]: isDeviceBlockedPage,
      })}
    >
      <div className={classes.upperContainer}>
        {isDeviceBlockedPage ? (
          <div className={classes.footer}>
            <Typography variant="p20" className={classes.footerText}>
              To continue playing Wish Lamp$ on your mobile device, please
              download the mobile app.
            </Typography>
            <AppDownload downloadClassName={classes.downloadBtn} />
          </div>
        ) : (
          <>
            {active_subscription ? (
              <div className={classes.button}>
                <Typography className={classes.footerText}>
                  Unlock the App on Your Mobile Device
                </Typography>
                <div>
                  <AppDownload downloadClassName={classes.downloadBtn} />
                </div>
              </div>
            ) : (
              <div className={classes.button}>
                <Button
                  buttonText="Impact Investment Program"
                  buttonColor="secondary"
                  onClick={() => {
                    if (window) {
                      (window as any).location = "/a/impact-investment-program";
                    }
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>
      <div className={classes.lowerContainer}>
        <div className={classes.leftSection}>
          <img src={Tsg} alt="img" height={60} width={120} />
          {!isDeviceBlockedPage && copyRightAndLink}
        </div>
        <div className={classes.rightSection}>
          {isDeviceBlockedPage && isMobile && (
            <div className={classes.footerButtonMobile}>
              <Button
                buttonText="Impact Investment Program"
                buttonColor="secondary"
                onClick={() => {
                  if (window) {
                    (window as any).location = "/a/impact-investment-program";
                  }
                }}
                buttonClassName={classes.secondaryButton}
              />
            </div>
          )}
          <div className={classes.footerLinks}>
            <a href={TGS_BASE_URL}>About Treasure Game$</a>
            <a href={`${TGS_BASE_URL}/a/tg-participation-agreement/`}>
              Participation Agreement
            </a>
            <a href={`${TGS_BASE_URL}/a/community-guidelines`}>
              Community Guidelines
            </a>
            <a href={`${TGS_BASE_URL}/a/impact-investment-program`}>
              Impact Investment Program
            </a>
            <a
              target="_blank"
              href={`https://tawk.to/chat/${tawkPropertyId}/${tawkWidgetId}`}
              rel="noreferrer"
            >
              Contact
            </a>
            <a href={`${TGS_BASE_URL}/a/employment`}>Employment</a>
            <a href={`${TGS_BASE_URL}/a/faq`}>FAQ</a>
            <a href={`${TGS_BASE_URL}/a/partners`}>Partners</a>
          </div>
          {isDeviceBlockedPage && (
            <div
              className={cn(classes.leftSection, {
                [classes.isDeviceBlock]: isDeviceBlockedPage,
              })}
            >
              {copyRightAndLink}
            </div>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
