import cx from "classnames";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import LeftIcon from "src/assets/icons/chevrons-left-icon.svg";
import PlayIcon from "src/assets/icons/controls/pause-icon.svg";
import HomeIcon from "src/assets/icons/home.svg";
import ContactIcon from "src/assets/icons/mail.svg";
import NotebookIcon from "src/assets/icons/notebook-icon.svg";
import LampIcon from "src/assets/icons/play-icon.svg";
import SettingsIcon from "src/assets/icons/settings-icon.svg";
import SpeakerphoneIcon from "src/assets/icons/speakerphone.svg";
import TrophyIcon from "src/assets/icons/trophy-icon.svg";
import UserIcon from "src/assets/icons/user-circle-icon.svg";
import User from "src/assets/icons/user-icon.svg";
import Typography from "src/components/Typography";
import {
  PRIZE_SUB_SLUG,
  PRIZE_SUB_TITLE,
  WATCH_SUB_SLUG,
  WATCH_SUB_SLUG_OBJ,
  WATCH_SUB_TITLE,
} from "src/helpers/constants";
import useCurrentPath from "src/helpers/hooks/useCurrentPath";
import TgsLogo from "src/pages/Home/assets/images/tgs-img.svg";
import { WATCH_TREASURE_GAME_URL1 } from "src/pages/Watch/constants";
import { NavItem } from "src/types";
import classes from "./styles.module.scss";

type Props = {
  expand: boolean;
  toggle: () => void;
};

const sidebarElements: NavItem[] = [
  {
    icon: UserIcon,
    name: "My Subscription",
    path: ["/subscriptions"],
  },
  {
    icon: HomeIcon,
    name: "My Dashboard",
    path: ["/play/my-dashboard"],
  },
  {
    icon: LampIcon,
    name: "Play",
    path: ["/play"],
  },
  {
    icon: PlayIcon,
    name: "Watch",
    path: ["/watch"],
    subs: WATCH_SUB_SLUG.map((slug: string) => {
      const activeSubs = [
        `/watch/user-profile/:username`,
        `/watch/user-profile/:username/followers`,
        `/watch/user-profile/TreasureGame$`,
        `/watch/user-profile/TreasureGame$/followers`,
        `/watch/:channelSlug/playing-media/:id/:name/:userId`,
        `/watch/:channelSlug/playing-media/:id`,
        `/watch/:channelSlug/:subChannelSlug/:subChannelTitle`,
        `/watch/:channelSlug/:subChannelSlug/:subChannelTitle/playing-media/:id/:name/:userId`,
        `/watch/:channelSlug/:subChannelSlug/:subChannelTitle/playing-media/:id`,
      ];
      if (slug === WATCH_SUB_SLUG_OBJ.MY_CHANNEL) {
        activeSubs.push("/watch/my-channel/manage-content");
        activeSubs.push(
          "/watch/my-channel/manage-content/post-details/:postId"
        );
      }
      return {
        name: String(WATCH_SUB_TITLE[slug] || slug),
        path:
          slug === WATCH_SUB_SLUG_OBJ.TREASURE_GAME
            ? [WATCH_TREASURE_GAME_URL1]
            : [`/watch/${slug}`],
        slug,
        activeSubs,
      };
    }),
  },
  {
    icon: TrophyIcon,
    name: "Prizes",
    path: ["/prizes"],
    subs: PRIZE_SUB_SLUG.map((slug: string) => {
      const activeSubs = [
        `/prizes/:channelSlug/playing-media/:id/:name/:userId/followers`,
        `/prizes/:channelSlug/playing-media/:id/:name/:userId`,
        `/prizes/:channelSlug/playing-media/:id`,
      ];
      return {
        name: String(PRIZE_SUB_TITLE[slug] || slug),
        path: [`/prizes/${slug}`],
        slug,
        activeSubs,
      };
    }),
  },
  {
    icon: SpeakerphoneIcon,
    name: "Announcements",
    path: ["/announcements"],
    activeSubs: [
      `/announcements/playing-media/:id`,
      `/announcements/playing-media/:id/:name/:userId`,
    ],
  },
  {
    icon: NotebookIcon,
    name: "Engagement",
    path: ["/engagement"],
    activeSubs: [
      `/engagement/playing-media/:id`,
      `/engagement/playing-media/:id/:name/:userId`,
    ],
  },
];

const sideFooterElements = [
  {
    icon: User,
    name: "My Profile",
    path: ["/user/profile"],
    isExternalLink: false,
  },
  {
    icon: SettingsIcon,
    name: "Settings",
    path: ["/user/settings"],
    isExternalLink: false,
  },
  {
    icon: ContactIcon,
    name: "Contact",
    path: ["https://www.treasuregames.fun/a/contact/"],
    isExternalLink: true,
  },
];

const Sidebar: React.FC<Props> = ({ expand, toggle }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const currentPath = useCurrentPath();
  const tComparePathName = currentPath || pathname;

  return (
    <div className={cx(classes.wrapper, { [classes.collapse]: !expand })}>
      <div className={classes.sidebarWrapper}>
        <div className={classes.navItems}>
          <div
            onClick={toggle}
            className={cx(classes.back, { [classes.rotate]: !expand })}
          >
            <img src={LeftIcon} alt="left" />
          </div>
          {sidebarElements.map(
            ({ icon, name, path, subs, activeSubs }, index) => {
              const tSubs = subs || [];
              const isSubExpand =
                path.includes(pathname) ||
                tSubs.filter(
                  (s) =>
                    s.path.includes(pathname) ||
                    (s.activeSubs || []).filter((ac) => ac === tComparePathName)
                      .length > 0
                ).length > 0;
              const isActiveByActiveSub =
                Number(activeSubs?.length) > 0 &&
                activeSubs?.includes(tComparePathName);
              return (
                <React.Fragment key={index}>
                  <div
                    onClick={() => navigate(path[0])}
                    key={index}
                    className={cx(classes.navItem, {
                      [classes.active]:
                        path.includes(pathname) || isActiveByActiveSub,
                      [classes.hasSub]: tSubs.length > 0,
                    })}
                  >
                    <img width={28} src={icon} alt="icon" />
                    {expand && (
                      <Typography
                        className="mb-0"
                        variant="p16"
                        fontFamily="inter"
                        fontWeight="semibold"
                      >
                        {name}
                      </Typography>
                    )}
                  </div>
                  {tSubs.length > 0 && expand && isSubExpand && (
                    <div className={classes.navSubs}>
                      {tSubs.map((sub, sIndex) => {
                        const tSubActiveSubs = sub.activeSubs || [];
                        const isIncludePathName = sub.path.includes(pathname);
                        const isIncludeSubActivePath =
                          tSubActiveSubs.filter(
                            (ac) =>
                              ac === tComparePathName &&
                              (pathname.includes(`/${sub.slug}/`) ||
                                (pathname.includes(
                                  "/watch/user-profile/TreasureGame$"
                                ) &&
                                  sub.slug ===
                                    WATCH_SUB_SLUG_OBJ.TREASURE_GAME))
                          ).length > 0;
                        return (
                          <div
                            className={cx(classes.navItem, classes.subNavItem, {
                              [classes.active]:
                                isIncludePathName || isIncludeSubActivePath,
                            })}
                            key={sIndex}
                            onClick={() => navigate(sub.path[0])}
                          >
                            <Typography
                              className="mb-0"
                              variant="p16"
                              fontFamily="inter"
                            >
                              {sub.name}
                            </Typography>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </React.Fragment>
              );
            }
          )}
        </div>
        <div className={classes.footerWrapper}>
          <div className={classes.footerItems}>
            {sideFooterElements.map(
              ({ icon, name, path, isExternalLink }, index) => {
                return (
                  <Link
                    key={index}
                    to={path[0]}
                    target={isExternalLink ? "_blank" : "_self"}
                    className={cx(classes.navItem, {
                      [classes.active]: path.includes(pathname),
                    })}
                  >
                    <img width={28} src={icon} alt="icon" />
                    {expand && (
                      <Typography
                        className="mb-0"
                        variant="p16"
                        fontFamily="inter"
                        fontWeight="semibold"
                      >
                        {name}
                      </Typography>
                    )}
                  </Link>
                );
              }
            )}
          </div>
          <div
            className={cx(classes.footerLogo, { [classes.addPadding]: expand })}
          >
            <img src={TgsLogo} alt="tg-logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
