import {
  FingerprintJSPro,
  FpjsProvider,
} from "@fingerprintjs/fingerprintjs-pro-react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { PersistGate } from "redux-persist/integration/react";
// eslint-disable-next-line no-restricted-imports
import "bootstrap/dist/css/bootstrap.min.css";
// eslint-disable-next-line no-restricted-imports
import "font-awesome/css/font-awesome.min.css";
// eslint-disable-next-line no-restricted-imports
import "react-datepicker/dist/react-datepicker.css";
// eslint-disable-next-line no-restricted-imports
import "slick-carousel/slick/slick.css";
// eslint-disable-next-line no-restricted-imports
import "slick-carousel/slick/slick-theme.css";
// eslint-disable-next-line no-restricted-imports
import "react-toastify/dist/ReactToastify.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./store";
import "./index.scss";

if (process.env.REACT_APP_NODE_ENV === "production") {
  ReactGA.initialize(process.env.REACT_APP_GA4_MEASUREMENT_ID || "");
  TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID || "" });
}

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PAYMENT_API_KEY || ""
);

const fingerPrintApiKey = process.env.REACT_APP_FINGERPRINT_API_KEY || "";
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <FpjsProvider
    loadOptions={{
      apiKey: fingerPrintApiKey,
      endpoint: [
        "https://fp.treasuregames.fun",
        FingerprintJSPro.defaultEndpoint,
      ],
      scriptUrlPattern: [
        "https://fp.treasuregames.fun/web/v<version>/<apiKey>/loader_v<loaderVersion>.js",
        FingerprintJSPro.defaultScriptUrlPattern,
      ],
    }}
  >
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <GoogleOAuthProvider clientId={googleClientId}>
            <Elements stripe={stripePromise}>
              <App />
              <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
              />
            </Elements>
          </GoogleOAuthProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </FpjsProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
