import React from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "reactstrap";
import Text from "src/components/Text";
import { useIsUserProfileFreeTrial } from "src/helpers/hooks/useUserProfile";
import { AdvantageClue } from "src/types/clue";

import classes from "./styles.module.scss";

type Props = {
  item: AdvantageClue;
  action: (type: string) => void;
  disabled?: boolean;
};

const AdvantageCluesCard: React.FC<Props> = ({ item, action, disabled }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isFreeTrial = useIsUserProfileFreeTrial();

  const getLabel = (type: string) => {
    switch (type) {
      case "range_finder":
        return "Miles";
      case "treasure_view_images":
        return "";
      default:
        return "";
    }
  };
  const getCaption = (type: string) => {
    switch (type) {
      case "hot_and_cold_barometer":
        return (
          <>
            <b>Available on day 120</b>
          </>
        );
      case "treasure_compass":
        return (
          <>
            <b>Available on day 140</b>
          </>
        );
    }
  };

  return (
    <div className={classes.card}>
      <div className={classes.header}>
        <div className={classes.titleContainer}>
          <img src={item.img} />
          <Text fontFamily="inter" size={24} fontWeight={"bold"} color={"#000"}>
            {item.title}
          </Text>
        </div>
        <div>
          {item.isForMobile ? (
            <div className={classes.mobileOnly}>
              <Text
                fontFamily="inter"
                size={14}
                fontWeight={"semibold"}
                color="#1c1c1c"
              >
                Mobile Only
              </Text>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className={classes.descriptionContainer}>
        <Text
          fontFamily="inter"
          size={14}
          fontWeight={"medium"}
          color="#000"
          style={{ lineHeight: "normal" }}
        >
          {item.description}
        </Text>
      </div>
      <div className={classes.footer}>
        <div className={classes.plansContainer}>
          {item.plans.map(
            (plan: { price: string; value: string }, index: number) => {
              return (
                <Text
                  key={index}
                  fontFamily="inter"
                  size={17}
                  fontWeight="bold"
                >
                  ${plan.price} - {plan.value} {getLabel(item.type)}
                </Text>
              );
            }
          )}
        </div>
        {getCaption(item.type) && (
          <p className={classes.caption}>{getCaption(item.type)}</p>
        )}
        {!item.isForMobile && (
          <div className={classes.buttonContainer}>
            <Button
              onClick={() => {
                if (isFreeTrial) {
                  searchParams.set("show_upgrade_modal", "true");
                  setSearchParams(searchParams);
                  return;
                }
                action(item.type);
              }}
              className={classes.webButton}
              disabled={disabled}
            >
              <div className={classes.btnChildren}>
                <img src={item.img} />
                <Text
                  fontFamily="inter"
                  size={24}
                  color={item.isForMobile ? "#2B2A79" : "#FFFFFF"}
                  fontWeight="bold"
                >
                  Buy Now
                </Text>
              </div>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdvantageCluesCard;
