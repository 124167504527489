import { useLocation, useNavigate } from "react-router";
import Spinner from "src/components/Spinner";
import ChannelBlock from "src/pages/Watch/components/ChannelBlock";
import { MediaInfo } from "src/types/media";
import { getBasicMediaInfo } from "src/utils/media";
import classes from "./Engagement.module.scss";
import ExternalBlock from "./components/ExternalBlock";
import { useMainData } from "./selectorData";

type EBlock = {
  title: string;
  link: string;
};

const TGS_BASE_URL = "https://www.treasuregames.fun/a";

const externalBlocks: EBlock[] = [
  {
    title: "Game Rules",
    link: "/wl-rules/",
  },
  {
    title: "Participation Agreement",
    link: "/tg-participation-agreement/",
  },
  {
    title: "Community Guidelines",
    link: "/community-guidelines/",
  },
  {
    title: "Terms & Conditions",
    link: "/terms-and-conditions/",
  },
];

const EngagementMain = () => {
  const { engagementsData, engagementsLoading } = useMainData();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const medias = (engagementsData?.posts || []).map((feedItem: MediaInfo) => {
    return {
      ...getBasicMediaInfo(feedItem),
      onClick: () => navigate(`${pathname}/playing-media/${feedItem.id}`),
    };
  });

  return (
    <div className={classes.mainWrapper}>
      {false && <Spinner center />}
      <div className={classes.externalWrapper}>
        {externalBlocks.map((block: EBlock, index: number) => {
          return (
            <ExternalBlock
              key={index}
              title={block.title}
              onClick={() => {
                const win = window.open(TGS_BASE_URL + block.link, "_blank");
                win?.focus();
              }}
            />
          );
        })}
      </div>
      <ChannelBlock
        medias={medias}
        hideButton
        paddingBottom
        loading={engagementsLoading}
        loadingBottom={!!engagementsData.meta?.next_cursor}
      />
    </div>
  );
};

export default EngagementMain;
