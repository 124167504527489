import find from "lodash/find";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { RegionListProps } from "src/pages/Play/SelectRegions/Components/RegionList/types";
import { useReducerData } from "src/store/hooks";
import {
  AdvantageClue,
  PurchaseHistoryProps,
  RangeFinder,
  RangePlanProps,
} from "src/types/clue";
import { useClueActions, useRegionActions } from "src/utils/useActions";
import { useCluesData, useRegionData } from "src/utils/useData";
import hotAndColdBarometer from "./assets/hot-and-cold-barometer.png";
import rangeFinder from "./assets/range-finder.png";
import treasureCompass from "./assets/treasure-compass.png";
import treasureViews from "./assets/treasure-views.png";
import AdvantageCluesCard from "./components/AdvantageCluesCard";
import HotColdBarometerModal from "./components/HotColdBarometerModal";
import PurchaseHistory from "./components/PurchaseHistory";
import RangeFinderModal from "./components/RangeFinderModal";
import TreasureCompassModal from "./components/TreasureCompassModal";
import TreasureViewsModal from "./components/TreasureViewsModal";
import classes from "./styles.module.scss";

const getAdvantageClueList = (
  rangeFinderOptions: RangePlanProps[] = [],
  treasureViewsOptions: RangePlanProps[] = [],
  barometerOptions: RangePlanProps[] = [],
  compassOptions: RangePlanProps[] = []
) => {
  const ADVANTAGE_CLUES: AdvantageClue[] = [
    {
      id: 1,
      title: "Range Finder",
      description: (
        <>
          Set a pin on a map to have the Range Finder check whether that pin is
          within the purchased distance from the Outer Zone.
          <br />
          <br />
          Distances can be purchased at certain time frames.{" "}
          <a
            href="https://www.treasuregames.fun/a/wl-advantage-clues#range-finder"
            target="_blank"
            rel="noreferrer"
          >
            Learn more...
          </a>
        </>
      ),
      plans: rangeFinderOptions,
      isForMobile: false,
      type: "range_finder",
      img: rangeFinder,
    },
    {
      id: 2,
      title: "Treasure Views",
      description: (
        <>
          You can purchase up to <b>five 2 Packs</b> of photo reveals that are
          taken from the perspective of the hidden lamp. One 2 Pack is available
          to purchase every 30 days starting on Day 30.{" "}
          <a
            href="https://www.treasuregames.fun/a/wl-advantage-clues#treasure-views"
            target="_blank"
            rel="noreferrer"
          >
            Learn more...
          </a>
        </>
      ),
      plans: treasureViewsOptions,
      isForMobile: false,
      type: "treasure_view_images",
      img: treasureViews,
    },
    {
      id: 3,
      title: "Hot & Cold Barometer",
      description: (
        <>
          The Hot & Cold Barometer Advantage Clue allows a player to activate a
          hot and cold interface that shows them if they are generally moving
          towards or away from the Outer Zone.
          <br />
          <br />
          The Barometer lasts for 10 continuous minutes.
        </>
      ),
      plans: barometerOptions,
      isForMobile: true,
      type: "hot_and_cold_barometer",
      img: hotAndColdBarometer,
    },
    {
      id: 4,
      title: "Treasure Compass",
      description: (
        <>
          The Treasure Compass Advantage Clue only unlocks for players who have
          successfully placed the <b>10 mile</b> pin for the{" "}
          <b>Range Finder Advantage Clue.</b> The Treasure Compass points a
          player in the direction of the Inner Zone. The Compass lasts for 10
          continuous minutes.
        </>
      ),
      plans: compassOptions,
      isForMobile: true,
      type: "treasure_compass",
      img: treasureCompass,
    },
  ];

  return ADVANTAGE_CLUES;
};

const CluesPurchase = () => {
  const { getRegion } = useRegionActions();
  const {
    region: {
      data: { id },
    },
  } = useRegionData();
  const [rangeFinderModal, setRangeFinderModal] = useState<boolean>(false);
  const [treasureViewsModal, setTreasureViewsModal] = useState<boolean>(false);
  const [hotcoldModal, setHotcoldModal] = useState<boolean>(false);
  const [treasureModal, setTreasureModal] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const {
    getRangeFinderBuyOptions,
    getAdvantageCluesPurchaseHistoryData,
    getTreasureViewsBuyOptions,
    resetAdvantageCluesData,
  } = useClueActions();
  const { region } = useRegionData();
  const { seasons = [] } = region.data;
  const currentSeason = searchParams.get("season");
  const season = seasons.find(
    (season) => season?.id?.toString() === currentSeason
  );
  const lampId = season?.lamp?.id as number;
  const purchasedTreasureViewsMedia = season?.lamp?.media || {
    purchased: 0,
    total: 10,
    purchased_media: [],
  };
  const { data: regionList }: { data: RegionListProps[] } = useReducerData(
    "region",
    "regionList",
    {
      data: [],
      loading: false,
    }
  );
  const thisRegion: any = find(regionList, {
    id: Number(searchParams.get("region")),
  });
  const regionLampData = thisRegion?.lamp_data || [];
  const thisLampData = find(regionLampData, { id: lampId });

  const [step, setStep] = useState(1);
  const [initialRangeFinderResultData, setInitialRangeFinderResultData] =
    useState<PurchaseHistoryProps>();

  const [initialTreasureViewsResultData, setInitialTreasureViewsResultData] =
    useState<PurchaseHistoryProps>();

  const refreshCluePurchaseData = () => {
    getRangeFinderBuyOptions(lampId);
    getTreasureViewsBuyOptions(lampId);
    getAdvantageCluesPurchaseHistoryData(lampId);
  };

  useEffect(() => {
    if (lampId) {
      refreshCluePurchaseData();
    } else {
      resetAdvantageCluesData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lampId]);

  const toggleModal = () => {
    setRangeFinderModal(false);
    setTreasureViewsModal(false);
    setHotcoldModal(false);
    setTreasureModal(false);
    setStep(1);
  };

  const handleOpenModal = (type: string) => {
    if (!lampId) return;
    if (type === "range_finder") {
      setRangeFinderModal(true);
    } else if (type === "treasure_view_images") {
      setTreasureViewsModal(true);
    } else if (type === "hot_and_cold_barometer") {
      setHotcoldModal(true);
    } else if (type === "treasure_compass") {
      setTreasureModal(true);
    } else {
      return;
    }
  };

  const handleOpenPurchaseHistoryModal = (history: PurchaseHistoryProps) => {
    if (history.clue.type === "range_finder") {
      setRangeFinderModal(true);
      setStep(4);
      setInitialRangeFinderResultData(history);
    } else {
      setTreasureViewsModal(true);
      setStep(3);
      setInitialTreasureViewsResultData(history);
    }
  };

  const updateRegion = async () => {
    getRegion(id.toString());
  };

  const { rangeFinderBuyOptions, treasureViewsBuyOptions } = useCluesData();

  const rangeFinderData = rangeFinderBuyOptions?.data || [];

  const formattedRangeFinderData =
    rangeFinderData
      ?.filter((op) => op?.type === "range_finder")
      ?.map((option: RangeFinder) => {
        return { price: option?.amount, value: option?.range?.toString() };
      }) || [];
  const formattedBarometerData =
    rangeFinderData
      ?.filter((op) => op?.type === "barometer")
      ?.map((option) => {
        return { price: option?.amount, value: "For 10 Minutes" };
      }) || [];
  const formattedCompassData =
    rangeFinderData
      ?.filter((op) => op?.type === "compass")
      ?.map((option) => {
        return { price: option?.amount, value: "For 10 Minutes" };
      }) || [];
  const formattedTreasureViewsData =
    treasureViewsBuyOptions?.data
      ?.filter(
        (op) => op?.type === "treasure_view_images" && op?.image_count === 2
      )
      ?.map((option) => {
        return {
          price: option?.amount || "",
          value:
            option?.image_count === 1
              ? "Single "
              : `${option?.image_count} Pack`,
        };
      }) || [];

  const advClues = getAdvantageClueList(
    formattedRangeFinderData,
    formattedTreasureViewsData,
    formattedBarometerData,
    formattedCompassData
  );

  return (
    <>
      {rangeFinderModal ? (
        <RangeFinderModal
          isOpen={rangeFinderModal}
          onClose={toggleModal}
          lampId={lampId}
          initialStep={step}
          initialResultData={initialRangeFinderResultData}
          refreshCluePurchaseData={refreshCluePurchaseData}
          lampData={thisLampData}
        />
      ) : null}
      {treasureViewsModal ? (
        <TreasureViewsModal
          isOpen={treasureViewsModal}
          onClose={toggleModal}
          lampId={lampId}
          purchasedTreasureViewsMedia={purchasedTreasureViewsMedia}
          initialStep={step}
          initialResultData={initialTreasureViewsResultData}
          refreshCluePurchaseData={refreshCluePurchaseData}
          updateRegion={updateRegion}
        />
      ) : null}
      {hotcoldModal ? (
        <HotColdBarometerModal isOpen={hotcoldModal} onClose={toggleModal} />
      ) : null}
      {treasureModal ? (
        <TreasureCompassModal isOpen={treasureModal} onClose={toggleModal} />
      ) : null}

      <div className={classes.mainContainer}>
        <div className={classes.hintText}>
          <span>
            The advantage clues may help you in your search for the Wish Lamp.
            You are responsible to read{" "}
            <a
              href="https://www.treasuregames.fun/a/wl-advantage-clues/"
              target="_blank"
              rel="noreferrer"
            >
              all the information related to their use
            </a>
            .
          </span>
        </div>
        <div className={classes.container}>
          {advClues.map((item: AdvantageClue, index) => {
            return (
              <AdvantageCluesCard
                item={item}
                key={index}
                action={handleOpenModal}
              />
            );
          })}
        </div>
        <PurchaseHistory handleOpenModal={handleOpenPurchaseHistoryModal} />
      </div>
    </>
  );
};

export default CluesPurchase;
